import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import utilitySprite from "@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg";
import standardSprite from "@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg";
import Spinner from "@salesforce/design-system-react/components/spinner";
import { Route, Switch, HashRouter } from "react-router-dom";
const NotFound = props => <div>Page Not Found :( </div>;
const App = lazy(() => import("./app/App.jsx"));
const ActivityRunning = lazy(() => import("./activityRunning/Activity.jsx"));
const Viber = lazy(() => import("./viber/Activity.jsx"));
const Zalo = lazy(() => import("./zalo/Activity.jsx"));
const Line = lazy(() => import("./line/Activity.jsx"));
const NGTrend = lazy(() => import("./ngtrend/Activity.jsx"));
const Twilio = lazy(() => import("./twilio/Activity.jsx"));


ReactDOM.render(
    <IconSettings utilitySprite={utilitySprite} standardSprite={standardSprite}>
        <HashRouter basename="/">
            <Suspense fallback={<Spinner size="large" variant="brand" assistiveText={{ label: "Loading" }} />}>
                <Switch>
                    <Route exact path="/viber" component={Viber} />
                    <Route exact path="/zalo" component={Zalo} />
                    <Route exact path="/line" component={Line} />
                    <Route exact path="/ngtrend" component={NGTrend} />
                    <Route exact path="/twilio" component={Twilio} />
                    <Route exact path="/app/setup/:channel/:market" component={App} />
                    <Route exact path="/app" component={App} />
                    <Route exact path="/activityRunning" component={ActivityRunning} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Suspense>
        </HashRouter>
    </IconSettings>,
    document.getElementById("root")
);
